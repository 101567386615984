// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notices-js": () => import("./../../../src/pages/legal-notices.js" /* webpackChunkName: "component---src-pages-legal-notices-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-services-canna-tamer-js": () => import("./../../../src/pages/services/canna-tamer.js" /* webpackChunkName: "component---src-pages-services-canna-tamer-js" */),
  "component---src-pages-services-careless-driving-lawyers-js": () => import("./../../../src/pages/services/careless-driving-lawyers.js" /* webpackChunkName: "component---src-pages-services-careless-driving-lawyers-js" */),
  "component---src-pages-services-cdl-lawyers-js": () => import("./../../../src/pages/services/cdl-lawyers.js" /* webpackChunkName: "component---src-pages-services-cdl-lawyers-js" */),
  "component---src-pages-services-dui-lawyers-js": () => import("./../../../src/pages/services/dui-lawyers.js" /* webpackChunkName: "component---src-pages-services-dui-lawyers-js" */),
  "component---src-pages-services-fake-id-lawyers-js": () => import("./../../../src/pages/services/fake-id-lawyers.js" /* webpackChunkName: "component---src-pages-services-fake-id-lawyers-js" */),
  "component---src-pages-services-mip-lawyers-js": () => import("./../../../src/pages/services/mip-lawyers.js" /* webpackChunkName: "component---src-pages-services-mip-lawyers-js" */),
  "component---src-pages-services-red-light-js": () => import("./../../../src/pages/services/red-light.js" /* webpackChunkName: "component---src-pages-services-red-light-js" */),
  "component---src-pages-services-speeding-ticket-lawyers-js": () => import("./../../../src/pages/services/speeding-ticket-lawyers.js" /* webpackChunkName: "component---src-pages-services-speeding-ticket-lawyers-js" */),
  "component---src-pages-services-stop-sign-js": () => import("./../../../src/pages/services/stop-sign.js" /* webpackChunkName: "component---src-pages-services-stop-sign-js" */),
  "component---src-pages-services-suspended-license-lawyers-js": () => import("./../../../src/pages/services/suspended-license-lawyers.js" /* webpackChunkName: "component---src-pages-services-suspended-license-lawyers-js" */),
  "component---src-pages-services-traffic-ticket-lawyers-js": () => import("./../../../src/pages/services/traffic-ticket-lawyers.js" /* webpackChunkName: "component---src-pages-services-traffic-ticket-lawyers-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

